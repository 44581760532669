// src/components/ui/Tabs.js
import React from 'react';
import '../tabs.css'; // Importa los estilos CSS

const Tabs = ({ children, value, onValueChange }) => {
  return (
    <div className="tab-container">
      <div className="tab-list">
        {React.Children.map(children, (child) =>
          child.type === TabList ? (
            React.cloneElement(child, { value, onValueChange })
          ) : null
        )}
      </div>
      {React.Children.map(children, (child) =>
        child.type === TabPanel ? (
          child.props.value === value ? child : null
        ) : null
      )}
    </div>
  );
};

const TabList = ({ children, value, onValueChange }) => (
  <div>
    {React.Children.map(children, (child) =>
      React.cloneElement(child, { isActive: child.props.value === value, onClick: () => onValueChange(child.props.value) })
    )}
  </div>
);

const Tab = ({ isActive, onClick, children }) => (
  <div
    className={`tab ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    {children}
  </div>
);

const TabPanel = ({ value, children }) => (
  <div className="tab-panel">
    {children}
  </div>
);

export { Tabs, TabList, Tab, TabPanel };
