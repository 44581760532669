// /components/ui/progress.jsx (if using JavaScript)
import React from 'react';
import PropTypes from 'prop-types';
import '../progress.css'; // Importing any associated CSS file for styling

const Progress = ({ value, max = 100 }) => {
  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${(value / max) * 100}%` }}>
        {value}%
      </div>
    </div>
  );
};

Progress.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
};

export default Progress;
