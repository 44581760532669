import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Tabs, TabList, Tab, TabPanel } from './components/ui/tabs';
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";
import { useParams } from 'react-router-dom';
import Progress from './components/ui/progress';

const Dashboard = () => {
  const { pirdata_id } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://x8ki-letl-twmt.n7.xano.io/api:Xmx6HgX7/pirdata/${pirdata_id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pirdata_id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const totalConfig = data.reduce((sum, item) => sum + item.pirData_system_config, 0);
  const totalKnowledge = data.reduce((sum, item) => sum + item.pirData_user_kwnoledge, 0);

  const processes = ['INVENTARIOS', 'PRODUCCIÓN', 'VENTAS', 'BANCOS', 'FINANZAS'];

const comentarios = {
  INVENTARIOS: [
    {question: 'Manejo de Lotes', comments: 'Las piedras no son iguales, hay lista de materiales pero sí hay una base.', recommendations: 'Estandarizar las listas de materiales y definir criterios claros para el manejo de lotes.', rating: 6},
    {question: 'Entradas de Mercadería', comments: 'Las hacen por ajustes de inventarios, no usan la entrada, sino que ingresan. No tienen el perfil del rol de encargado de inventarios. Para hacer traslados y solicitudes.', recommendations: 'Capacitar al personal en el uso correcto de las entradas de mercadería y definir roles claros.', rating: 4},
    {question: 'Salidas de Mercadería', comments: 'Si hacen un proceso de autorización por fuera de SAP.', recommendations: 'Implementar el proceso de autorización dentro de SAP para mayor control y trazabilidad.', rating: 5},
    {question: 'Solicitud y Traslado de Mercancías', comments: 'No los hacen.', recommendations: 'Implementar el uso de solicitudes y traslados de mercancías en SAP.', rating: 2},
    {question: 'Inventario Físico', comments: 'Dos veces al año.', recommendations: 'Aumentar la frecuencia de los inventarios físicos para mejorar la precisión del inventario.', rating: 7},
    {question: 'Revalorización de Costos', comments: 'Sí hacen desde enero, porque empezaron millones, pero ya van en Q30,000.00. Aunque saben que no se debe hacer. Así se dejó parametrizado.', recommendations: 'Revisar y ajustar la parametrización para evitar revalorizaciones incorrectas.', rating: 3},
    {question: 'Datos Maestro de Inventarios', comments: 'La fiabilidad del inventario está en un 80% de insumos y servicios. Solo el 50% tiene códigos. Sin manejo de máximos y mínimos.', recommendations: 'Completar la codificación de todos los productos y establecer niveles de máximos y mínimos.', rating: 5},
  ],
  PRODUCCIÓN: [
    {question: 'Lista de Materiales', comments: 'Sí tienen por tipo de mármol.', recommendations: 'Revisar y actualizar periódicamente las listas de materiales.', rating: 8},
    {question: 'Costos de Producción Directos', comments: 'Se tienen que revisar los conceptos de aplicación de factores.', recommendations: 'Revisar y definir claramente los conceptos de costos de producción directos.', rating: 6},
    {question: 'Costos de Producción Indirectos', comments: 'El costeo de producción debe definirse de nuevo para analizar lo que mejor les convenga, si es directo o de absorción.', recommendations: 'Realizar un análisis detallado para definir el método de costeo más adecuado.', rating: 5},
    {question: 'Uso de Recursos', comments: 'Aún no lo hacen y sería bueno.', recommendations: 'Implementar el uso de recursos en SAP para mejorar la gestión de la producción.', rating: 3},
    {question: 'Recibo de Producción', comments: 'Lo ingresa una operadora, con un reporte manual que llena un operador.', recommendations: 'Automatizar el proceso de recibo de producción para reducir errores manuales.', rating: 4},
    {question: 'Emisión de Producción', comments: 'Lo ingresa una operadora, con un reporte manual que llena un operador.', recommendations: 'Automatizar el proceso de emisión de producción para mejorar la eficiencia.', rating: 4},
    {question: 'Manejo de Merma', comments: 'La trazabilidad de las planchas del bloque, en el proceso final, es el que más varía.', recommendations: 'Mejorar la trazabilidad de las mermas para optimizar el proceso de producción.', rating: 5},
  ],
  VENTAS: [
    {question: 'Acuerdos Globales', comments: 'Sí existe el escenario para proyectos.', recommendations: 'Revisar y ajustar los acuerdos globales según las necesidades del proyecto.', rating: 7},
    {question: 'Cotización de Ventas', comments: 'No se definieron las condiciones de venta, revisión del formato en SAP.', recommendations: 'Definir y estandarizar las condiciones de venta en SAP.', rating: 4},
    {question: 'Orden de Venta', comments: 'Pueden subir pedido con inventario negativo, no hay una reserva de las planchas. El pedido no descarga del inventario hasta que tienen la OF. El detalle es que otro vendedor lo vende y se quedan sin MP comprometida.', recommendations: 'Implementar reservas de inventario y ajustar el proceso de descarga de inventario.', rating: 3},
    {question: 'Entregas/Despachos', comments: 'Logística coordina las entregas con el estatus en el sistema de terminado.', recommendations: 'Mejorar la coordinación entre logística y el sistema SAP para reflejar el estado real de las entregas.', rating: 6},
    {question: 'Picking List', comments: 'Sí arman en Excel la estrategia de entrega.', recommendations: 'Implementar el uso de picking list en SAP para mejorar la eficiencia.', rating: 4},
    {question: 'Lista de Precios', comments: 'No están cerrados, los pueden cambiar a gusto. Pero sí hay un nivel de autorización al Gerente de Ventas. No hay rangos ni tipos de clientes.', recommendations: 'Definir y cerrar las listas de precios con niveles de autorización y rangos de clientes.', rating: 5},
    {question: 'Precios Especiales', comments: 'Sí les serviría.', recommendations: 'Implementar precios especiales en SAP para mejorar la gestión de ventas.', rating: 6},
    {question: 'Factura a Clientes', comments: 'No tiene ningún problema, revisión de los roles de facturación según cada escenario.', recommendations: 'Revisar y ajustar los roles de facturación según los diferentes escenarios.', rating: 8},
    {question: 'Factura de Reserva', comments: 'La usan muy poco, pero a veces los clientes piden ese tipo de factura. / 5 a 7 facturas al mes. Sí existe una autorización por fuera de SAP. No genera costo, se tiene que hacer una provisión e impacta en el momento de pagar el ISR.', recommendations: 'Automatizar el proceso de autorización de facturas de reserva en SAP.', rating: 5},
  ],
  BANCOS: [
    {question: 'Impresión de Cheques', comments: 'No tienen todos los formatos de cheques. Bancos.', recommendations: 'Actualizar y configurar todos los formatos de cheques necesarios.', rating: 4},
    {question: 'Asistente de Pago', comments: 'No tienen todos los datos del SN.', recommendations: 'Completar y actualizar los datos del socio de negocio (SN) en SAP.', rating: 5},
    {question: 'Conciliación Bancaria', comments: 'Todas las empresas las hacen en Excel. PDG sí las hace en el sistema.', recommendations: 'Implementar la conciliación bancaria en SAP para todas las empresas.', rating: 3},
    {question: 'General', comments: 'Adaptar el formato de la conciliación. Movimientos en circulación. (la carátula).', recommendations: 'Adaptar los formatos de conciliación y movimientos en circulación en SAP.', rating: 4},
  ],
  FINANZAS: [
    {question: 'Catálogo Contable', comments: 'Objetivo homologar catálogos por grupos de trabajo. Un catálogo corporativo.', recommendations: 'Homologar los catálogos contables y crear un catálogo corporativo.', rating: 6},
    {question: 'Centros de Costos', comments: 'Solo usan una dimensión.', recommendations: 'Implementar el uso de múltiples dimensiones en los centros de costos.', rating: 4},
    {question: 'Plantillas Periódicas', comments: 'Capa.', recommendations: 'Capacitar al personal en el uso de plantillas periódicas.', rating: 5},
    {question: 'Diferencia de TC y de Conversión', comments: 'Capa / no lo actualizan diario. El AddOn para todas las empresas.', recommendations: 'Actualizar diariamente las diferencias de tipo de cambio y conversión.', rating: 4},
    {question: 'Modelo Financiero', comments: 'Capa.', recommendations: 'Revisar y ajustar el modelo financiero según las necesidades de la empresa.', rating: 5},
    {question: 'Cierre Contable', comments: 'Solo en PDG está hecho.', recommendations: 'Implementar el cierre contable en todas las empresas.', rating: 3},
  ],
};

const ProcessTab = ({ process }) => {
  const processData = comentarios[process] || [];
  
  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">{process} Dashboard</h2>
      {processData.map((item, index) => (
        <Card key={index} className="mb-4">
          <CardHeader>
            <CardTitle>{item.question}</CardTitle>
          </CardHeader>
          <CardContent>
            <p><strong>Comentarios:</strong> {item.comments}</p>
            <p><strong>Recomendaciones:</strong> {item.recommendations}</p>
            <div className="mt-2">
              <strong>Rating:</strong>
              <Progress value={item.rating * 10} className="mt-2" />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};


  const Commentarios = () => {
    const [activeTab, setActiveTab] = useState(processes[0]);

    return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">SAP Business One Dashboard</h1>
        <Tabs value={activeTab} onValueChange={setActiveTab}>
  <TabList>
    {processes.map(process => (
      <Tab key={process} value={process}>{process}</Tab>
    ))}
  </TabList>
  {processes.map(process => (
    <TabPanel key={process} value={process} isSelected={activeTab === process}>
      <ProcessTab process={process} />
    </TabPanel>
  ))}
</Tabs>
      </div>
    );
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Dashboard de Reporte</h1>
      <p className="mb-4">
        Estado de Aprovechamiento de SAP Business One
        PIR es la herramienta que utilizamos en Grupo Inforum, en nuestra división de Base instalada, como acompañamiento y seguimiento para nuestros clientes implementados en años anteriores, consiste en:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>2-3 visitas realizadas por un consultor senior SAP Business One</li>
        <li>5 a 6 grupos de entrevistas con los colaboradores de la empresa, con el objetivo de escuchar y recabar información de cómo están usando SAP y que nivel de conocimiento tienen de la herramienta.</li>
        <li>Levantado de información, la cual posteriormente se analiza módulo por módulo y se obtienen datos estadísticos como:
          <ul className="list-disc pl-5">
            <li>Configuración del Sistema</li>
            <li>Conocimiento de usuarios</li>
            <li>Aprovechamiento de la herramienta</li>
          </ul>
        </li>
      </ul>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <Card>
          <CardHeader>
            <CardTitle>Configuración del Sistema General</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{totalConfig.toFixed(2)}%</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Conocimiento del Usuario</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{totalKnowledge.toFixed(2)}%</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Herramientas</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">N/A</p>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Gráfico de Datos por Proceso</CardTitle>
        </CardHeader>
        <CardContent>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="pirData_process_name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pirData_system_config" fill="#8884d8" name="Configuración del Sistema" />
              <Bar dataKey="pirData_user_kwnoledge" fill="#82ca9d" name="Conocimiento del Usuario" />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      <Commentarios />
    </div>
  );
};

export default Dashboard;